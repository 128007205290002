.side-img {
    background-image: linear-gradient(
        180.7deg,
        rgba(109, 180, 18, 0.17) 1.06%,
        #037b40 78.13%
    ),
    url("../assets/images/sideimage.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
}

.side-img__text {
    align-self: flex-end;
}

.form-container {
    display: flex;
    justify-content: center;
}

.form-child {
    align-self: center;
}

.new-acc-link {
    right: 0;
}

