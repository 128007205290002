.hideImportant {
  display: none!important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link { 
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.page-content-attendance {
    margin-top: 15px;
}

.kpi-children .child {
    position: relative;
    color: #3A3C3F !important;
    box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    border-radius: 5px;
    background-color: #fff;
}


/* Ay's */

.child {
    margin: 4px 4px;
}

.buttons {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
}



.spinner {
    -webkit-animation: dash 2s linear infinite;
            animation: dash 2s linear infinite;
    -webkit-animation-direction: normal;
            animation-direction: normal;
  }
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 150.6 100.4;
    }
    50% {
      stroke-dasharray: 1 250;
    }
    100% {
      stroke-dashoffset: 502;
      stroke-dasharray: 150.6 100.4;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 150.6 100.4;
    }
    50% {
      stroke-dasharray: 1 250;
    }
    100% {
      stroke-dashoffset: 502;
      stroke-dasharray: 150.6 100.4;
    }
  }
  @-webkit-keyframes spinner-in {
    0% {
      opacity: 0;
    }
    20%, 80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes spinner-in {
    0% {
      opacity: 0;
    }
    20%, 80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .svg-toggle-password {
    fill: rgb(80,200,120);
  }

  .svg-toggle-password .closed-eye {
    opacity: 0;
    height: 0;
  }

  .open-eye {
    opacity: 1;
    transition: opacity 300ms ease, height 400ms ease;
  }

  .react-tel-input .form-control {
      width: 100%!important;
  }
  .profile-mgt .react-tel-input input {
      width: 100%!important;
  }

  .react-tel-input .country-list .search-box {
    width: 90%!important;
  }