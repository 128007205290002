@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  --main-bg-color: coral;
}

*:focus {
  outline: 0 !important;
}

a {
  color: #0d894d;
}

a:hover {
  text-decoration: none;
  color: #0b683b;
}

a:active {
  color: #0b683b;
}

html {
  font-size: 14px;
}

body {
  color: #2e343b;
  /* color: #788090; */
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

body::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  width: 10px;
}

/* Perfect ScrollBar Begin */

/*
 * Container style
 */

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
   * Scrollbar rail styles
   */

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
   * Scrollbar thumb styles
   */

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/* Perfect Scrollbar end */

.main-wrapper {
  display: flex;
  overflow: hidden;
}

.main-wrapper .page-wrapper {
  min-height: 100vh;
  background: #f7f8fa;
  width: calc(100% - 280px);
  margin-left: 280px;
  display: flex;
  flex-direction: column;
  transition: margin 0.1s ease, width 0.1s ease;
}

.main-wrapper .sidebar {
  width: 280px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transition: width 0.1s ease, margin 0.1s ease-out;
  z-index: 999;
}

.navbar {
  width: calc(100% - 280px);
  height: 64px;
  background: #fff;
  border-bottom: 1px solid #eceeed;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  right: 0;
  left: 280px;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
}

.navbar .navbar-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.navbar .sidebar-header .sidebar-toggler {
  font: inherit;
  display: inline-flex;
  overflow: visible;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.navbar .sidebar-toggler-box {
  width: 25px;
  height: 24px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.navbar .sidebar-toggler-icon:after,
.navbar .sidebar-toggler-icon:before {
  display: block;
  content: "";
}

.navbar .sidebar-toggler-icon:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navbar .sidebar-toggler-icon:before {
  top: 16px;
}

.navbar .sidebar-toggler-icon:after {
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    opacity 0.1s linear;
}

.navbar .sidebar-toggler-icon:after {
  bottom: -8px;
}

.navbar .sidebar-toggler-icon,
.navbar .sidebar-toggler-icon:after,
.navbar .sidebar-toggler-icon:before {
  position: absolute;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #0d894d;
}

.navbar .sidebar-toggler-icon {
  width: calc(0.85 * 25px);
  transition: all 0.2s linear;
}

.navbar .sidebar-toggler-icon:after {
  width: 25px;
}

.navbar .sidebar-toggler-icon::before {
  width: calc(0.7 * 0.85 * 25px);
}

.nav-toggler-close {
  display: none;
}

/* active state begin */

.navbar .sidebar-toggler.is-active .sidebar-toggler-icon {
  width: 25px;
  transform: rotate(90deg);
  opacity: 0.7;
  transform-origin: 10px 10px;
  transition: all 0.2s linear;
}

.navbar .sidebar-toggler.is-active .sidebar-toggler-icon:after {
  width: 25px;
}

.navbar .sidebar-toggler.is-active .sidebar-toggler-icon:before {
  width: 25px;
}

/* active state end */

.sidebar .sidebar-header .sidebar-toggler {
  font: inherit;
  display: inline-flex;
  overflow: visible;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.sidebar .sidebar-header .sidebar-toggler-box {
  width: 25px;
  height: 24px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.sidebar .sidebar-toggler-icon:after,
.sidebar .sidebar-toggler-icon:before {
  display: block;
  content: "";
}

.sidebar .sidebar-toggler-icon:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.sidebar .sidebar-toggler-icon:before {
  top: 16px;
}

.sidebar .sidebar-toggler-icon:after {
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    opacity 0.1s linear;
}

.sidebar .sidebar-toggler-icon:after {
  bottom: -8px;
}

.sidebar .sidebar-toggler-icon,
.sidebar .sidebar-toggler-icon:after,
.sidebar .sidebar-toggler-icon:before {
  position: absolute;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #0d894d;
  /* background-color: rgba(42, 56, 63, 0.678); */
}

.sidebar .sidebar-toggler-icon {
  width: calc(0.85 * 25px);
  transition: all 0.2s linear;
}

.sidebar .sidebar-toggler-icon:after {
  width: 25px;
}

.sidebar .sidebar-toggler-icon::before {
  width: calc(0.7 * 0.85 * 25px);
}

/* active state begin */

.sidebar-toggler.is-active .sidebar-toggler-icon {
  width: 25px;
  height: 3px;
  transform: rotate(90deg);
  opacity: 0.7;
  transform-origin: 10px 10px;
  transition: all 0.2s linear;
}

.sidebar-toggler.is-active .sidebar-toggler-icon:after {
  width: 25px;
  height: 3px;
}

.sidebar-toggler.is-active .sidebar-toggler-icon:before {
  width: 25px;
  height: 3px;
}

/* active state end */

.sidebar-toggler-mobile {
  display: none;
}

.navbar .navbar-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.navbar .navbar-content .navbar-nav {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.navbar .navbar-content .navbar-nav .nav-item {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  width: 30px;
  display: flex;
  align-items: center;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link {
  color: #535353;
  padding: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link .ring {
  display: block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #0d894d;
}

.main-wrapper .page-wrapper .page-content {
  flex-grow: 1;
  padding: 6px 12px;
  margin-top: 64px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .main-wrapper .page-wrapper .page-content {
    padding: 5px 10px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main-wrapper .page-wrapper .page-content {
    padding: 5px 10px;
  }
}

.main-wrapper .page-wrapper .page-content-inner {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: flex-start;
}

.page-content .page-content-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .page-content-inner .inner-nav {
    display: none;
  }
  .page-content-title {
    display: flex;
    align-items: center;
  }
  .page-hamburger {
    background-color: none;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-hamburger:hover svg,
  .page-hamburger:active svg {
    stroke: #0d894d;
  }
  .inner-nav.mobile {
    position: absolute !important;
    top: -10px;
    left: 10px;
    display: block;
    z-index: 4;
  }
  .inner-nav.mobile .nav {
    border: 1px solid #d4d4d4;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2),
      0 2px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0px 0px rgba(255, 255, 255, 0.5);
  }
  .inner-nav.mobile .nav .details {
    display: none;
  }
}

@media (min-width: 1025px) {
  .page-hamburger {
    display: none;
  }
}

.page-content-inner .inner-nav {
  margin-right: 7.5px;
  width: 260px !important;
  flex: 0 0 260px;
  position: relative;
}

.page-content-inner .nav {
  width: 260px;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 5px;
  transition: all 0.5s ease-in-out;
}

.page-content-inner .nav.fixed-on-scroll {
  position: fixed;
  top: 76px;
  width: 260px;
}

.page-content-inner .nav a {
  width: 100%;
  color: inherit;
  margin-bottom: 1px;
}

.page-content-inner .nav a.navlist:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.116) !important;
  border-radius: 5px !important;
}

.page-content-inner .nav a.navlist:not(.active):active {
  background-color: rgba(0, 0, 0, 0.116) !important;
  border-radius: 5px !important;
}

.page-content-inner .nav a.navlist.active {
  border-radius: 5px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.page-content-inner .nav .content {
  width: 100%;
  padding: 5px 7px;
  border-radius: 7px;
}

.text-muted {
  color: rgba(108, 117, 125, 0.741) !important;
}

.page-content-inner .nav .content .title {
  font-weight: 400;
  margin-bottom: 1px;
  font-size: 1.02rem;
}

.page-content-inner .nav .content .details {
  font-size: 0.8rem;
}

.page-content-inner .inner-page {
  margin-left: 7.5px;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  background: #fff;
  border-radius: 5px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .page-content-inner .inner-page {
    margin-left: 0px;
  }
}

.inner-page .my-card {
  padding: 10px 10px;
}

.inner-page .my-card .my-card-header {
  padding: 0px 0px 8px 0;
  font-size: 1.2rem;
}

.inner-page .my-card .my-card-header.flex {
  display: flex;
}

.inner-page .my-card .my-card-header.flex .right {
  margin-left: auto;
}

.inner-page .my-card .my-card-header.flex .right .button {
  font-size: 0.8rem;
}

.inner-page .my-card .my-card-header.flex .right .button:last-child {
  margin-right: 0 !important;
}

.inner-page .my-card .my-card-body {
  padding: 8px;
}

.inner-page .my-card .my-card-body .prof-img {
  padding: 8px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.inner-page .my-card .my-card-body .prof-img .image {
  border: solid 2px #e7e9eb;
  width: 130px;
  height: 130px;
  overflow: hidden;
  padding: 2px;
  border-radius: 50%;
  /* box-shadow:  -1px 1px 5px #ccc;; */
}

.inner-page .my-card .my-card-body .prof-img .name {
  margin: 10px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.05rem;
}

.inner-page .my-card .my-card-body .prof-img .id {
  font-weight: 400;
  font-size: 1.05rem;
}

.inner-page .my-card .my-card-body .prof-img > div > img {
  width: 100%;
  height: auto;
}

.inner-page .my-card .my-card-sub-header {
  padding: 8px 0 8px 0;
  margin-bottom: 8px;
  font-size: 1.1rem;
}

.mini-card {
  padding: 7px 0 0 7px;
  border-radius: 7px;
  border: solid 1px #e7e9eb;
  margin: 10px 0 10px;
}

.mini-card + .mini-card {
  margin-top: 30px;
}

.mini-card-header {
  font-size: 1.05rem;
  margin-bottom: 15px;
}

.mini-card .details {
  display: flex;
  border-bottom: solid 1px #e7e9eb;
  align-items: center;
  padding: 10px 5px;
}

.mini-card .details:last-child {
  border-bottom: none !important;
}

.mini-card .details .label {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  flex-basis: 30%;
  width: 30%;
}

.mini-card .details .value {
  font-size: 1rem;
}

.my-card-body .tax {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.my-card-body .tax > div {
  width: 33.33%;
  margin: 0 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .mini-card .details {
    flex-direction: column;
    align-items: flex-start;
  }
  .mini-card .details .label {
    flex-basis: 100%;
    width: 100%;
    font-size: 0.65rem;
    margin-bottom: 5px;
  }
  .my-card-body .tax {
    flex-flow: column nowrap;
    align-items: stretch;
  }
  .my-card-body .tax > div {
    width: 100%;
    margin: 0 20px;
  }
}

.attendance {
  display: flex;
  align-items: center;
}

.attendance div:nth-child(1) {
  width: 100px;
}

/* Sidebar starts here  */

.sidebar .sidebar-header {
  background: #fff;
  height: 64px;
  border-bottom: 1px solid #eceeed;
  display: flex;
  align-items: center;
  padding: 0 25px 0 10px;
  /* border-right: 1px solid #f2f4f9; */
  z-index: 999;
  width: 100%;
  transition: width 0.1s ease;
}

.sidebar-logo {
  display: block;
  margin-left: 10px;
}

.sidebar-logo a {
  text-align: center;
}

.sidebar-logo a img {
  width: 55%;
  margin: 0 auto;
}

.sidebar .sidebar-header {
  border-bottom: 1px solid #eceeed;
}

.sidebar .sidebar-header .sidebar-brand {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease;
  /* width: max-content; */
}

.sidebar .sidebar-header .sidebar-brand span {
  color: #0d894d;
  font-weight: 300;
}

.sidebar .sidebar-body {
  max-height: calc(100% - 60px);
  position: relative;
  height: 100%;
  box-shadow: 0 8px 6px 0 rgba(183, 192, 206, 0.2);
  background: #fff;
}

.sidebar .sidebar-body .nav {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 50px 0px;
}

.sidebar .sidebar-body .nav .nav-item {
  position: relative;
}

.sidebar .sidebar-body .nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 25px;
  height: 45px;
  white-space: nowrap;
  color: inherit;
}

.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
  width: 16px;
  height: 16px;
  fill: rgba(233, 236, 239, 0.21);
  stroke: #2e343b;
  position: absolute;
  color: inherit;
}

.sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  margin-left: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.sidebar .sidebar-body .nav .nav-item .nav-link.active svg {
  strokewidth: 1.5px;
}

.sidebar .sidebar-body .nav .nav-item .nav-link.active .link-title {
  max-width: 185px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .sidebar-body .nav .nav-item .nav-link.active {
  color: #0d894d;
  border-radius: 0 22px 22px 0;
  background-color: rgba(0, 123, 64, 0.09);
}

.sidebar .sidebar-body .nav .nav-item .nav-link.active svg {
  stroke: #0d894d !important;
}

.sidebar .sidebar-body .nav .nav-item .nav-link:hover .link-icon {
  color: #0d894d;
}

.sidebar .sidebar-body .nav .nav-item .nav-link:hover .link-title {
  color: #0d894d;
}

.sidebar .sidebar-body .nav .nav-item .nav-link:hover svg {
  stroke: #0d894d !important;
}

.navbar .navbar-content .navbar-nav .nav-item {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  width: 30px;
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdownAnimation,
.navbar-nav .nav-item.dropdown .dropdown-menu {
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
  max-width: 400px;
  position: absolute;
  right: -20px;
  left: auto;
  border-radius: 5px;
  margin-top: 0;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2),
    0 2px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0px 0px rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  /* font-size: 0.875rem; */
  width: 320px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
    width: 250px !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
    width: 280px !important;
  }
}

.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu::before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -7px;
  right: 28px;
  transform: rotate(45deg);
  border-top: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
}

.navbar .dropdown-menu .dropdown-header {
  padding-top: 0;
  padding-left: 12px;
  padding-bottom: 3px;
  color: #2e343b;
}

.navbar .dropdown-menu .dropdown-header .head {
  font-weight: 500;
  font-size: 1.5rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* background-color: #FBFBFB; */
  border-radius: 5px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: inherit !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* background-color: #FBFBFB; */
  border-radius: 5px !important;
}

.dropdown-item-bef {
  padding: 0 5px;
}

.navbar .dropdown-menu .dropdown-body .dropdown-item {
  border-radius: 5px;
  padding: 9px 7px;
  display: flex;
  transition: background 0.2s ease-in-out;
}

.navbar .dropdown-menu .dropdown-body .dropdown-item .content {
  width: 100%;
  margin-left: 10px;
}

.navbar .dropdown-menu .dropdown-body .dropdown-item .content > p {
  margin-bottom: 0;
}

.navbar .dropdown-menu .dropdown-body .dropdown-item .content > div > p {
  margin-bottom: 0;
}

.navbar .dropdown-menu .dropdown-body .dropdown-item .content .sub-text {
  font-size: 0.8rem;
}

.navbar .dropdown-body .dropdown-item .figure img {
  border-radius: 50%;
  width: 40px;
  height: auto;
  overflow: hidden;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 0.3rem;
  clear: both;
  font-weight: 400;
  color: inherit;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.navbar .dropdown.nav-profile.show .dropdown-menu {
  padding: 7px !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .navbar .dropdown.nav-profile.show .dropdown-menu.show {
    right: -12px !important;
  }
}

.navbar .dropdown.nav-profile.show .dropdown-menu .top .content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px 0;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .top + hr {
  background: #d4d4d4;
  border-width: 0;
  color: #d4d4d4;
  height: 1px;
  margin: 0.5rem 0.3rem;
  margin-top: 0.2rem;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .dropdown-item + hr {
  background: #d4d4d4;
  border-width: 0;
  color: #d4d4d4;
  height: 1px;
  margin: 0.3rem 0.3rem;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .top .content .figure img {
  border-radius: 50%;
  width: 60px;
  height: auto;
  overflow: hidden;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .top .content .info {
  width: 100%;
  margin-left: 12px;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .top .content .info p {
  margin-bottom: 0;
  margin-left: 0 !important;
}

.navbar
  .dropdown.nav-profile.show
  .dropdown-menu
  .top
  .content
  .info
  p.text-muted {
  font-size: 0.9rem;
  margin-left: 0 !important;
  margin-top: -1px;
  white-space: break-spaces;
}

.navbar
  .dropdown.nav-profile.show
  .dropdown-menu
  .top
  .content
  .info
  p:not(.text-muted) {
  font-weight: 500;
  font-size: 1.08rem;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .dropdown-item {
  padding: 10px 5px;
}

.navbar .dropdown.nav-profile.show .dropdown-menu .dropdown-item .content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.navbar
  .dropdown.nav-profile.show
  .dropdown-menu
  .dropdown-item
  .content
  .thumb {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(46, 52, 59, 0.07);
}

.navbar .dropdown.nav-profile.show .dropdown-menu .dropdown-item .content p {
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
}

.nav-profile {
  width: 35px !important;
}

.nav-company {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  font-size: 0.8rem;
}

.nav-profile a img {
  width: 100%;
}

.navbar .sidebar-toggler.mobile {
  display: none;
}

.sidebar-folded .page-wrapper .navbar {
  width: calc(100% - 70px);
  left: 70px;
  right: 0;
}

.sidebar-folded .page-wrapper {
  width: calc(100% - 70px);
  margin-left: 70px;
}

/* .sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header {
  width: 280px;
}
 */

.sidebar-folded:not(.open-sidebar-folded) .sidebar {
  width: 70px;
}

.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body {
  width: 70px;
}

.sidebar-folded.open-sidebar-folded .sidebar .sidebar-body {
  background-color: rgb(241, 241, 241);
  transition: all 0.2s ease-in;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link.active {
  background: none;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link.active::before {
  content: "";
  width: 0px;
  height: 70%;
  background: #0d894d;
  position: absolute;
  left: -4px;
  border-radius: 20px;
  border: 3px solid #0d894d;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link.active
  svg {
  stroke: #0d894d;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link
  .link-arrow,
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link
  .link-title {
  visibility: hidden;
  opacity: 0;
}

footer.footer p.text-muted.text-center {
  font-size: 0.7rem;
  font-weight: 500;
}

@media (min-width: 481px) and (max-width: 767px) {
  body.sidebar-open {
    overflow: hidden !important;
  }
  .navbar {
    width: 100%;
    flex-flow: row nowrap;
    left: 0;
  }
  .sidebar .sidebar-toggler {
    display: none !important;
  }
  .sidebar .sidebar-header {
    border-right: 2px solid #eceeed;
    border-bottom: 2px solid #eceeed;
    box-shadow: 0 8px 8px 0 rgba(183, 192, 206, 0.2);
  }
  .sidebar .sidebar-header .sidebar-logo {
    text-align: center;
  }
  .sidebar .sidebar-header button.nav-toggler-close {
    display: block;
    background: none;
    border: none;
    margin-right: -15px;
  }
  .sidebar .sidebar-body {
    border-right: 2px solid #eceeed;
    box-shadow: 0 8px 8px 0 rgba(183, 192, 206, 0.2);
  }
  .sidebar-open .page-wrapper {
    filter: blur(2px);
  }
  .navbar .sidebar-toggler.mobile {
    display: block;
    margin-left: 10px;
  }
  .navbar-content {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .nav-company {
    display: none !important;
  }
  .main-wrapper .page-wrapper {
    margin-left: 0;
    width: 100%;
  }
  .sidebar {
    z-index: 999;
    margin-left: -240px;
    visibility: hidden;
    transition: all 0.5s ease-in-out !important;
  }
  .sidebar-open .sidebar {
    margin-left: 0;
    visibility: visible;
    transition: all 0.5s ease-in-out;
  }
  .sidebar .sidebar-body .nav .nav-item {
    width: auto;
  }
  .sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
    transition: none;
    margin: 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  body.sidebar-open {
    overflow: hidden !important;
  }
  .navbar {
    width: 100%;
    flex-flow: row nowrap;
    left: 0;
  }
  .sidebar .sidebar-toggler {
    display: none !important;
  }
  .sidebar .sidebar-header {
    border-right: 2px solid #eceeed;
    border-bottom: 2px solid #eceeed;
    box-shadow: 0 8px 8px 0 rgba(183, 192, 206, 0.2);
  }
  .sidebar .sidebar-header .sidebar-logo {
    text-align: left;
  }
  .sidebar .sidebar-header button.nav-toggler-close {
    display: block;
    background: none;
    border: none;
    margin-right: -15px;
  }
  .sidebar .sidebar-body {
    border-right: 2px solid #eceeed;
    box-shadow: 0 8px 8px 0 rgba(183, 192, 206, 0.2);
  }
  .sidebar-open .page-wrapper {
    opacity: 0.6;
  }
  .navbar .sidebar-toggler.mobile {
    display: block;
    margin-left: 10px;
  }
  .navbar-content {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .nav-company {
    display: none !important;
  }
  .main-wrapper .page-wrapper {
    margin-left: 0;
    width: 100%;
  }
  .sidebar {
    z-index: 999;
    margin-left: -240px;
    visibility: hidden;
    transition: all 0.5s ease-in-out !important;
  }
  .sidebar-open .sidebar {
    margin-left: 0;
    visibility: visible;
    transition: all 0.5s ease-in-out;
  }
  .sidebar .sidebar-body .nav .nav-item {
    width: auto;
  }
  .sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
    transition: none;
    margin: 0;
  }
}

.button {
  display: inline-flex;
  height: 2.65rem;
  align-items: center;
  justify-content: center;
  transform: perspective(1px) translateZ(0);
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  border-radius: 3px;
  font-weight: 500;
  padding: 0px 1rem;
  vertical-align: middle;
}

.button:hover {
  text-decoration: none;
}

.button:disabled,
.button[disabled] {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.button.danger {
  /* box-shadow: 0px 1px 30px 0px rgba(13, 137, 77, 0.1); */
  color: #fff;
  background-color: #dc3545;
  border: 1.5px solid #dc3545;
}

.button.danger:hover {
  color: #fff;
  box-shadow: none;
  background: #dc3545;
}

.button.primary {
  /* box-shadow: 0px 1px 30px 0px rgba(13, 137, 77, 0.1); */
  color: #fff;
  background-color: #0d894d;
  border: 1.5px solid #0d894d;
  transition: backgroud-color 500s ease-in-out;
}

.button svg {
  width: 18px !important;
  height: 18px !important;
}

.button.primary:hover {
  color: #fff;
  box-shadow: none;
  background: #0b7944;
}

.button.primary svg {
  strokewidth: 2.7px !important;
  stroke: #fff !important;
  margin-right: 4px;
}

.button.primary-light {
  color: #0d894d;
  background-color: rgba(11, 121, 68, 0.1);
}

.button.primary-light:hover {
  background: #0b794431;
  box-shadow: none;
}

.button.secondary {
  color: #5f6368;
  background-color: inherit;
  /* box-shadow: inset 0 0 0 1.5px #dadce0; */
  border: 1.5px solid #dadce0;
  transition: backgroud-color 500s ease-in-out;
}

.button.secondary svg {
  strokewidth: 1.7px !important;
  stroke: #7e8388 !important;
  margin-right: 5px;
}

.form-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 40px;
  text-align: right;
  font-size: 0.9rem;
}

.tx-left {
  text-align: left;
}

.button.form-aux {
  border-radius: 1.5rem;
}

.button.secondary:hover {
  background-color: rgba(32, 33, 36, 0.059);
}

.button.secondary-light {
  color: inherit;
  background-color: #f7f8fa;
}

.button.secondary-light:hover {
  background: rgb(236, 236, 238);
  box-shadow: none;
}

.flatpickr-input[readonly] {
  background-color: inherit !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.mini-card .row {
  margin: 0 !important;
}

.mini-card .row:last-child {
  margin-bottom: 10px !important;
}

.mini-card .form-group {
  padding-right: 40px;
}

.form-group {
  padding: 10px;
  padding-bottom: 0;
}

input.form-control,
select.form-control {
  display: block;
  width: 100%;
  padding: 0.2rem 0.7rem;
  line-height: 1;
  color: #5f6368;
  background-clip: padding-box;
  transition: border-color 0.3s ease-in-out;
  font-weight: 400;
  border: solid 1.5px #dadce0;
  border-radius: 4px;
  height: 2.6rem;
  transition: all 0.3s ease-in-out;
}

textarea.form-control {
  display: block;
  width: 100%;
  height: 8rem !important;
  padding: 0.5rem 0.5rem;
  line-height: 1;
  color: #5f6368;
  background-clip: padding-box;
  transition: border-color 0.3s ease-in-out;
  font-weight: 400;
  border: solid 1.5px #dadce0;
  border-radius: 4px;
  height: 2.6rem;
  transition: all 0.3s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #32312f;
  outline: 0;
  box-shadow: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: #d2d2d9 !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  font-weight: 400;
  color: #d2d2d9 !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-weight: 400;
  color: #d2d2d9 !important;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  font-weight: 400;
  color: #d2d2d9 !important;
}

input::placeholder,
textarea::placeholder {
  font-weight: 400;
  color: #d2d2d9 !important;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #939597 !important;
  color: #4f5257 !important;
}

input:disabled {
  cursor: not-allowed;
}

.filter {
  width: auto;
  height: 2rem;
  border-radius: 3px;
  font-size: 0.8rem;
  color: #5f6368;
  background-color: inherit;
  border: 1.5px solid #dadce0;
  transition: backgroud-color 300s ease-in-out;
  display: inline-flex;
  height: 2.65rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  border-radius: 3px;
  font-weight: 500;
  padding: 0px 1rem;
  vertical-align: middle;
}

.form-group label {
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
  font-weight: 400;
}

.is-invalid {
  background-image: none !important;
}

.invalid-feedback {
  display: block !important;
}

/* Slider Checkboxes */

.tg-list {
  text-align: center;
  display: flex;
  align-items: center;
}

.tg-list-item {
  margin: 0 0em;
  display: inline-block;
}

.tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.tgl + .tgl-btn:after {
  left: 0;
}

.tgl + .tgl-btn:before {
  display: none;
}

.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-ios + .tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #bbbdbc;
}

.tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}

.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}

.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}

.tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}

.tgl-ios:checked + .tgl-btn {
  background: #0d894d;
}

.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}

.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

/* Custom radio button */

.custom-control-label::before,
.custom-control-label::after {
  top: 0.15rem;
  outline: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0d894d;
  background-color: #0d894d;
  outline: none !important;
}

input[type="radio"] {
  outline: none !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  outline: none !important;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

/* Custom table */

.table-div {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.my-card-body .table-div {
  margin: 20px 0 50px;
  border-radius: 5px;
}

.mini-card .table-div {
  margin: 0 15px 15px 0;
  border-radius: 5px;
}

table.my-table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 400;
}

.my-table th {
  background-color: #fbfafa;
  border-bottom: 2px solid #e7e9eb;
  /* border-top: 2px solid #e7e9eb; */
  padding: 9px;
  font-weight: 400;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
}

.my-table td {
  border-bottom: 1px solid #e7e9eb;
  padding: 5px;
}

.my-table th:last-child {
  text-align: center;
}

.my-table td:last-child {
  text-align: center;
}

.my-table button {
  background: none;
  border: none;
}

.my-table.leave td:not(:first-child) {
  text-align: center;
}

.my-table.leave th:not(:first-child) {
  text-align: center;
}

.my-table button svg {
  width: 25px;
  height: auto;
  strokewidth: 1.3px !important;
}

.my-table button:hover svg.icon-tabler-edit {
  stroke: #379adb;
}

.my-table button:hover svg.icon-tabler-trash {
  stroke: red;
}

/* Modals */

.modal {
  background-color: rgba(128, 128, 128, 0.19);
  background-color: rgba(128, 128, 128, 0.19);
}
.modal.show {
  display: block;
}
.modal-body {
  position: relative;
  border: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.modal-body .image {
  margin: 1rem 0;
}

.modal-body .close {
  position: absolute;
  right: calc(0.5rem + 2px);
  top: calc(0.2rem + 2px);
}

.modal-body p {
  margin: 1rem;
  font-weight: 500;
}

.modal-body .modal-buttons {
  margin: 1rem 0;
}

.modal-body .modal-buttons button {
  margin: 0 15px;
  border-radius: 2px;
}

.my-card-body .dsnSettings {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
  width: 100%;
  margin-left: auto;
  align-items: center;
  padding: 5px;
}

.my-card-body .dsnName {
  width: 50%;
  flex: 0 0 1;
  font-size: 1rem;
  margin-left: 4rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .my-card-body .dsnName {
    margin-left: 0rem;
  }
}

.dsnName.head {
  font-weight: 500;
}

.my-card-body .dsnAction {
  width: 50%;
  flex: 0 0 1;
  text-align: center;
}

.mini-card .mini-card {
  padding: 15px 10px;
  margin-right: 15px;
}

.my-card-body .dsnAction button {
  background: none;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(46, 52, 59, 0.07);
}

.my-card-body .dsnAction button svg {
  width: 25px;
  height: auto;
  strokewidth: 1.3px !important;
}

.my-card-body .dsnAction button:hover svg.icon-tabler-edit {
  stroke: #379adb;
}

.my-card-body .dsnAction button:hover svg.icon-tabler-trash {
  stroke: red;
}

.my-table.training th:nth-child(4),
.my-table.training td:nth-child(4),
.my-table.training th:nth-child(5),
.my-table.training td:nth-child(5) {
  text-align: center;
}

.my-table button:hover svg.icon-tabler-edit {
  stroke: #379adb;
}

.my-table button:hover svg.icon-tabler-eye {
  stroke: #0d894d;
}

.staff_ss {
  align-items: flex-start !important;
}

.staff_ss .value {
  font-size: 0.9rem !important;
}

.access-panel {
  display: grid;
  grid-auto-rows: minmax(30px, auto);
  grid-template-columns: 200px repeat(5, 1fr);
  gap: 10px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 10px 0;
  border-bottom: 1px solid #e8eae9;
}

.access-panel:last-child {
  border-bottom: none;
}

.access-panel.header {
  font-weight: 500;
  margin-bottom: 1rem;
  border-bottom: none;
}

.access-panel .value {
  padding: 10px auto;
}

.access-panel .name {
  justify-self: start;
  align-self: center;
  font-weight: 500;
}

.access-panel label {
  margin-bottom: 0 !important;
}

.dashboard {
  position: relative;
}

.dashboard ._top {
  /* margin: -15px -15px 15px; */
  background-color: #fff;
  padding: 50px calc(15px + 20px);
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  /* box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2); */
  box-shadow: 1px 1px 3px rgb(92 92 92 / 20%);
  border-bottom: 2px solid #eceeed;
}

.dashboard ._xt {
  display: flex;
  align-items: center;
  max-width: 50%;
  /* border: solid #0b683b 1px; */
}

._top ._bt {
  margin-left: auto;
}

._xt ._pic a img {
  width: 80px;
  margin-right: 0.8rem;
}

._xt ._detail ._salut {
  font-size: 1.4rem;
  font-weight: 500;
}

._xt ._detail ._dte {
  font-size: 0.9rem;
  color: rgba(108, 117, 125, 0.741);
}

._detail ._other {
  display: flex;
  flex-flow: row wrap;
  margin-top: 4px;
}

._detail ._other ._item {
  font-size: 0.8rem;
  max-width: 80%;
}

._detail ._other ._item ._thumb {
  color: rgba(108, 117, 125, 0.741);
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 500;
  margin-right: 1em;
  transition: color 0.3s ease-in-out;
}

._detail ._other ._item a._thumb:hover {
  color: rgb(108, 117, 125);
}

._detail ._other ._item ._thumb .text {
  display: flex;
  align-items: flex-start;
}

._detail ._other ._item ._thumb svg {
  margin-right: 0.3em;
}

.dashboard .header {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 5px;
}

.dashboard ._bxs {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.dashboard ._bxs .bx {
  width: calc(100% / 4 - 20px);
  background-color: #fff;
  border-radius: 5px;
  height: auto;
  /* box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2); */
  /* box-shadow: 1px 1px 4px 0 rgba(183, 192, 206, 0.2); */
  box-shadow: 1px 1px 3px rgb(92 92 92 / 20%);
  overflow: hidden;
}

.dashboard ._bxs .bx ._bd {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 1.5rem;
}

.dashboard ._bxs .bx ._icn {
  margin-right: 0.6rem;
  color: rgba(108, 117, 125, 0.741);
  padding: 0.1em;
  border-radius: 5px;
}

.dashboard ._bxs .bx ._lbl {
  /* color: rgba(108, 117, 125, 0.741); */
  color: rgba(47, 63, 82, 0.788);
  font-weight: 500;
  font-size: 0.8rem;
}

.dashboard ._bxs .bx ._vle {
  font-weight: 500;
  font-size: 1.3rem;
}

.dashboard ._bxs .bx .ft a {
  background-color: rgba(0, 123, 64, 0.03);
  height: 2.3rem;
  padding: 0rem 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  color: rgba(108, 117, 125, 1);
}

.dashboard ._bxs .bx.purple .ft a {
  background: rgba(155, 89, 182, 0.024);
}

.dashboard ._bxs .bx.purple .ft a:hover {
  background: rgba(155, 89, 182, 0.2);
}

.dashboard ._bxs .bx.yellow .ft a {
  background: rgba(241, 196, 15, 0.024);
}

.dashboard ._bxs .bx.yellow .ft a:hover {
  background: rgba(241, 196, 15, 0.2);
}

.dashboard ._bxs .bx.blue .ft a {
  background: rgba(52, 152, 219, 0.024);
}

.dashboard ._bxs .bx.blue .ft a:hover {
  background: rgba(52, 152, 219, 0.2);
}

.dashboard ._bxs .bx.red .ft a {
  background: rgba(231, 77, 60, 0.024);
}

.dashboard ._bxs .bx.red .ft a:hover {
  background: rgba(231, 77, 60, 0.2);
}

.dashboard ._bxs .bx.purple ._icn {
  color: #9b59b6;
}

.dashboard ._bxs .bx.yellow ._icn {
  color: #f1c40f;
}

.dashboard ._bxs .bx.blue ._icn {
  color: #3498db;
}

.dashboard ._bxs .bx.red ._icn {
  color: #e74c3c;
}

.dashboard ._bxs .bx .ft a:hover {
  background-color: rgba(11, 104, 59, 0.11);
}

.dashboard ._evts {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 15px;
}

.dashboard ._evts ._ev {
  width: calc(100% / 2 - 20px);
  background-color: #fff;
  border-radius: 5px;
  height: auto;
  box-shadow: 1px 1px 3px rgb(92 92 92 / 20%);
  overflow: hidden;
}

.dashboard ._evts ._ev ._tp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.8rem 1rem 0;
}

.dashboard ._evts ._ev ._tp ._nme {
  color: rgba(47, 63, 82, 0.788);
  font-weight: 500;
  font-size: 0.8rem;
}

.dashboard ._evts ._ev ._tp ._ctrls a {
  margin: 0 0.1em;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
}

.dashboard ._evts ._ev ._tp ._ctrls a:hover {
  background-color: rgba(11, 104, 59, 0.11);
}

.dashboard ._evts ._ev ._bdy {
  font-weight: 500;
  font-size: 1.2rem;
  color: #0d894d;
  padding: 1rem 1rem;
}

.dashboard ._evts ._ev ._bdy ._ddte {
  /* color: #0d894d; */
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 1rem;
  font-size: 0.96rem;
  color: rgba(47, 63, 82, 0.988);
}

.dashboard ._evts ._ev ._ftr {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard ._evts ._ev .ft a {
  background-color: rgba(0, 123, 64, 0.03);
  height: 2.3rem;
  padding: 0rem 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  color: #0d894d;
}

.dashboard ._evts ._ev .ft a:hover {
  background-color: rgba(11, 104, 59, 0.11);
}

.dashboard .q_link {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.dashboard .q_link .link {
  display: inline-flex;
  /* width: calc(100% / 5 - 20px); */
  box-shadow: 1px 1px 3px rgb(92 92 92 / 20%);
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  margin: 5px;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: rgba(47, 63, 82, 0.788); */
  color: rgb(13 137 77 / 88%);
  font-weight: 500;
  height: 120px;
  width: 120px;
  transition: color 0.3s ease-in-out;
}

.dashboard .q_link .link div {
  margin-top: 0.2em;
  font-size: 0.9rem;
  color: rgba(108, 117, 125, 1);
  text-align: center;
}

.dashboard .q_link .link:hover {
  color: rgb(13 137 77 / 100%);
  background-color: #fdfdfd;
}

.dashboard .q_link .link:hover div {
  color: rgb(58, 63, 66);
}

@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard ._top {
    padding: 25px 25px;
  }
  .dashboard ._xt {
    max-width: 80%;
  }
  .dashboard ._bxs {
    flex-flow: row wrap;
  }
  .dashboard ._bxs .bx {
    width: calc(100% / 2 - 20px);
    margin-bottom: 1rem;
  }
  body:not(.sidebar-folded) .dashboard ._top {
    padding: 25px 25px;
    flex-direction: column;
  }
  body:not(.sidebar-folded) .dashboard ._xt {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  body:not(.sidebar-folded) ._xt ._pic a img {
    width: 80px;
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  body:not(.sidebar-folded) ._xt ._detail ._salut {
    font-size: 1.3rem;
    font-weight: 500;
  }
  body:not(.sidebar-folded) ._xt ._detail {
    text-align: center;
  }
  body:not(.sidebar-folded) ._detail ._other {
    display: flex;
    flex-flow: column wrap;
    margin-top: 4px;
    align-items: center;
  }
  body:not(.sidebar-folded) ._top ._bt {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .dashboard ._top {
    padding: 25px 25px;
    flex-direction: column;
  }
  .dashboard ._xt {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  ._xt ._pic a img {
    width: 80px;
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  ._xt ._detail ._salut {
    font-size: 1.3rem;
    font-weight: 500;
  }
  ._xt ._detail {
    text-align: center;
  }
  ._detail ._other {
    display: flex;
    flex-flow: column wrap;
    margin-top: 4px;
    align-items: center;
  }
  ._top ._bt {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
  }
  .dashboard ._bxs {
    flex-flow: column wrap;
  }
  .dashboard ._bxs .bx {
    width: calc(100%);
    margin-bottom: 1rem;
  }
  .dashboard ._evts {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .dashboard ._evts ._ev {
    width: calc(100%);
    margin-bottom: 1rem;
  }
  .dashboard .q_link .link {
    width: calc(100vw / 3.5);
    height: calc(100vw / 3.5);
  }
}

.kpi-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 180px;
  margin-bottom: 3rem;
  grid-template-rows: auto;
}

.kpi-header .lbl {
  border: 1px solid #e8ecee;
  white-space: nowrap;
  padding: 7px 20px;
  text-align: center;
  border-right: none;
}

.kpi-group {
  margin-bottom: 2rem;
}

.kpi-group .kpi-main {
  font-size: 1.2rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: 25px 1fr 180px;
  grid-template-rows: auto;
  padding: 10px 0px;
}

.kpi-group .kpi-main .lbl {
  justify-self: center;
}

.kpi-children {
  padding-left: 10px;
  font-size: 1rem;
}

.kpi-caret {
  justify-self: center;
}

/* .kpi-children .child {
  display: grid;
  grid-template-columns: 18px 1fr 180px;
  grid-template-rows: auto;
  position: relative;
  color: #3a3c3f !important;
  border-bottom: 1px solid #e8ecee;
} */

/* Ayo's styles */

.kpi-children .child {
  /* display: grid;
  grid-template-columns: 18px 1fr 180px;
  grid-template-rows: auto; */
  position: relative;
  color: #3a3c3f !important;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

/* Ay's */

.child {
  margin: 4px 4px;
  /* display: flex; */
}

.buttons {
  width: 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.karet {
  border-left: none;
  border-right: none;
  padding: 5px 0;
}

.kdesc {
  padding: 5px 0 5px 0;
  border-left: none;
  border-right: none;
  /* Ay's */
  /* width: 90%; */
  /* margin: auto; */
  align-items: center;
  justify-content: space-around;
  display: flex;
}

/* Ay's */

.percent {
  width: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ach {
  margin: 0;
}

.kpi-children .child button {
  padding: inherit;
  margin: 0;
  border: none;
  background: none;
  outline: none;
}

.kpi-children .child .lbl {
  justify-self: center;
  border-left: 1px solid #e8ecee;
  padding: 5px 0;
  border-top: 1px solid #e8ecee;
}

.kpi-children .child .lbl input {
  text-align: center;
  height: 100%;
}

.kpi-children .child ._ction {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background: none;
}

/* .kpi-children .child:hover {
  /* background-color: #f6f8f9; 
} */

.kpi-children .child:focus-within {
  border-radius: 3px;
  background-color: #f6f8f9;
}

.kpi-children .child:hover input {
  background-color: transparent;
}

.kpi-children .child:hover ._ction {
  display: block;
}

.kpi-children .child input {
  width: 100%;
  height: auto;
  border: none;
  padding: 0;
  color: #4d4d4d !important;
  border-radius: 2px;
  border: 1px solid transparent;
}

.kpi-children .child input:hover {
  border: 1px solid #495057;
}

.kpi-children .child input:focus {
  border: 1px solid #2e343b;
  background-color: #fff;
}

.kpi-children .child.add {
  border: none !important;
  color: #6f7782 !important;
}

.kpi-children .child .add {
  grid-column: 2/4;
  justify-self: start;
  padding: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  width: 100%;
  text-align: left;
}

.kpi-children .child .add:hover {
  background-color: #f6f8f9;
}

.workflow.header {
  display: grid;
  grid-template-columns: 1fr 150px 100px 1fr;
  grid-template-rows: auto;
}

.workflow.header div {
  border: 1px solid #e0e0e0;
  padding: 5px;
  color: #929496;
  font-size: 0.9rem;
  border-left: none;
}

.workflow.header div:last-child {
  border-right: none;
  text-align: center;
}

.workflow.item {
  display: grid;
  grid-template-columns: 1fr 150px 100px 1fr;
  grid-template-rows: auto;
  font-size: 0.9rem;
}

.workflow .head {
  margin-top: 10px;
  padding: 10px 0 10px;
  font-size: 1.2rem;
  font-weight: 500;
}

.workflow.item div {
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-right: none;
  vertical-align: middle;
}

.workflow.item + .workflow.item div {
  border-top: none !important;
}

.workflow.item div:first-child {
  border-left: none;
  display: flex;
}

.workflow .dte {
  color: #929496;
}

.workflow .prc span {
  display: inline-block;
}

.workflow .viw {
  margin-left: auto;
  display: none;
  text-decoration: underline;
  font-weight: 500;
}

.workflow.item .prc:hover .viw {
  display: inline-block !important;
}

.workflow .nme {
  font-size: 0.85rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.workflow button {
  font-size: 0.7rem;
  background-color: transparent;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border-style: solid;
  border-width: 2px;
  border-image: none;
  font-weight: 500;
}

.workflow button:hover {
  background-color: #f6f8f9;
}

.workflow button.app {
  border-color: #9ad9c6;
  color: #66cfb8;
}

.workflow button.rej {
  border-color: #f6b9bd;
  color: #f86d79;
}

.workflow button.req {
  color: #f8bf7b;
  border-color: #fbdec4;
}

.c_modal {
  display: none;
  align-items: center;
  justify-content: center;
}

.c_modal.show {
  display: flex !important;
}

._myModal {
  background-color: #fff;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  display: block;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
}

.modal.show ._myModal {
  -webkit-transform: none;
  transform: none;
  max-height: 100%;
  overflow: auto;
}

._myModal.req {
  min-width: 500px;
  min-height: calc(10% - 3.5rem);
}

._myModal.mesg {
  max-width: 800px;
  min-height: calc(10% - 3.5rem);
}

._myModal ._modal_header {
  border-bottom: 1px solid #e8ecee;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-shrink: 0;
  padding: 10px 15px;
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
}

._myModal ._modal_header button {
  border: none;
  background: none;
  border-radius: 5px;
  position: absolute;
  right: 8px;
  top: 8px;
  height: 28px;
  min-height: 28px;
  min-width: 28px;
  width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

._myModal ._modal_header button:hover {
  background-color: #f6f8f9;
}

._myModal ._modal_header button svg {
  fill: #6f7782;
  height: 16px;
  width: 16px;
}

._modal-content {
  padding: 15px 15px 20px;
}

._modal-content ._list {
  display: grid;
  grid-template-columns: 2fr 5fr;
  padding: 5px 0;
  gap: 20px;
}

._modal-content ._list .lbel {
  color: #6f7782;
  font-size: 0.8rem;
}

._modal-content ._list ._vl {
  font-weight: 500;
  font-size: 0.9rem;
}

._modal-content ._dbut {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* loader */

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #0d894d;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

._modal-content ._dbut button {
  font-size: 0.8rem;
  background-color: transparent;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border-style: solid;
  border-width: 2px;
  border-image: none;
  font-weight: 500;
  margin-right: 5px;
}

._modal-content ._dbut button:hover {
  background-color: #f6f8f9;
}

._modal-content ._dbut button.app {
  border-color: #9ad9c6;
  color: #66cfb8;
}

._modal-content ._dbut button.rej {
  border-color: #f6b9bd;
  color: #f86d79;
}

._modal-content ._dbut button.req {
  color: #f8bf7b;
  border-color: #fbdec4;
}

._myModal.mesg .title {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

._myModal ._mem_o {
  margin: 1rem 0;
  line-height: 1.9;
}

._myModal.prof {
  min-width: 50vw;
  height: 80vh;
  min-height: calc(10% - 3.5rem);
}

._myModal.prof ._modal-content {
  overflow-y: scroll;
  height: 90%;
}

._myModal .prof-img {
  padding: 8px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

._myModal .prof-img .image {
  border: solid 2px #e7e9eb;
  width: 130px;
  height: 130px;
  overflow: hidden;
  padding: 2px;
  border-radius: 50%;
  /* box-shadow:  -1px 1px 5px #ccc;; */
}

._myModal .prof-img .name {
  margin: 10px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.05rem;
}

._myModal .prof-img .id {
  font-weight: 400;
  font-size: 1.05rem;
}

._myModal .prof-img > div > img {
  width: 100%;
  height: auto;
}

._myModal.job_offer {
  min-width: 500px;
  max-width: 800px;
  min-height: calc(10% - 3.5rem);
}

._myModal.job_offer .name {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6;
}

._myModal.job_offer .title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6;
}

._myModal.job_offer ._add {
  font-weight: 500;
  margin-bottom: 2rem;
  line-height: 1.9;
}

._myModal.job_offer .mail {
  line-height: 1.9;
}

.applicant-list .applicant:hover {
  background-color: #fbfafa;
  border-radius: 3px;
}

.applicant-list .applicant {
  display: grid;
  grid-template-columns: 4fr 2fr 40px;
  padding: 10px;
  color: initial;
}

.applicant-list .applicant .nme {
  font-weight: 500;
}

.applicant-list .applicant .bullet {
  color: #6f7782;
}

.applicant-list .applicant:not(:last-child) {
  border-bottom: 1px solid #e9eaeb;
}

.applicant-details .detail {
  display: grid;
  grid-template-columns: 200px auto;
  padding: 5px 0;
  gap: 20px;
}

.applicant-details .detail .lbl {
  color: #6f7782;
  font-size: 0.9rem;
}

.applicant-details .detail .vlv {
  font-weight: 500;
  justify-content: start;
}

.applicant-comment {
  background-color: #f6f8f9;
  padding: 20px;
  position: relative;
  margin-top: 50px;
  border-radius: 7px;
  border: solid 1px #e7e9eb;
}

.applicant-comment .comments {
  padding: 5px 0px;
}

.applicant-comment .comments .comment {
  padding: 15px 0px 15px;
  border-bottom: #e8ecee 1px solid;
}

.applicant-comment .comments .comment .fst {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.applicant-comment .comments .comment .dte {
  color: #6f7782;
  display: inline;
  font-size: 0.8rem;
  margin-left: 8px;
  white-space: nowrap;
}

.applicant-comment .comments .comment .nme {
  font-weight: 500;
}

.applicant-comment .comments .comment .cmt {
  font-size: 0.9rem;
  margin-top: 2px;
  width: 95%;
}

.applicant-comment .thumb {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.applicant-comment .thumb img {
  width: 100%;
}

.applicant-comment .grr {
  display: grid;
  grid-template-columns: 32px auto;
  gap: 10px;
}

.applicant-comment .comment-box {
  padding: 15px 0px 5px;
}

.applicant-comment .comment-box button {
  margin-top: 20px;
}

.applicant-actions {
  display: flex;
  margin: 20px 0;
}

.applicant-actions .button {
  margin-right: 1rem;
}

.notice-board-list .notice:hover {
  background-color: #fbfafa;
  border-radius: 3px;
}

.notice-board-list .notice {
  display: grid;
  grid-template-columns: 20px 2fr 7fr 100px;
  gap: 20px;
  padding: 10px;
  color: initial;
  position: relative;
}

._notifications .notice {
  grid-template-columns: 20px 1fr 100px;
}

._notifications .notice.unread .dtl {
  font-weight: 500;
}

._notifications .notice .dtl {
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: clip !important;
}

.notice-board-list .notice .check {
  text-align: center;
}

.notice-board-list .notice.unread .status {
  display: inline-block;
}

.notice-board-list .notice .status {
  display: none;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: #0099ff;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.notice-board-list .notice .nme {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notice-board-list .notice .dtl {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notice-board-list .notice .date {
  color: #6f7782;
  font-size: 0.9rem;
}

.notice-board-list .notice:not(:last-child) {
  border-bottom: 1px solid #e9eaeb;
}

.my-card.view-notice {
  padding: 2rem 2rem;
}

.view-notice .notice-top {
  display: flex;
  align-items: center;
}

.view-notice .notice-top .user {
  display: flex;
  align-items: flex-start;
}

.view-notice .notice-top .nvg {
  margin-left: auto;
  border-radius: 20px;
  height: 2.2rem;
  /* border: 1px solid #929496; */
  box-shadow: 0 0 14px 5px rgba(146, 148, 150, 0.14);
  display: flex;
  margin-right: 1rem;
  color: #929496;
}

.view-notice .notice-top .nvg .nv {
  height: 100%;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.view-notice .notice-top .nvg .nv:first-child {
  border-right: 1px solid #929496;
}

.view-notice .notice-top .user .dt .name {
  font-size: 1.3rem;
  color: #495057;
}

.view-notice .notice-top .user .dt .time {
  font-size: 0.8rem;
  color: #7e8388;
}

.view-notice .notice-top .user .thumb {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
}

.view-notice .notice-top .user .thumb img {
  width: 100%;
}

.view-notice .sbj {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.view-notice .cnt {
  line-height: 1.7;
}

.inner-page.chat {
  display: flex;
  height: 82vh;
  min-height: 500px;
  margin-bottom: 1rem;
}

.inner-page.chat .message_list {
  flex-basis: 30%;
  /* box-shadow: 1px 0 2px rgba(77, 77, 77, 0.192); */
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
}

.inner-page.chat .message_list .search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* border-bottom: 1px solid #cdcdcd; */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.inner-page.chat .message_list .search .search_bar {
  width: 90%;
}

.inner-page.chat .message_list .search input {
  background-color: #f5f5f5;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  height: 2.5rem;
}

.inner-page.chat .message_list .new {
  /* border-top: 1px solid #cdcdcd; */
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-page.chat .message_list .list_main {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 5px 5px;
}

.inner-page.chat .message_list .list_main .list {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.inner-page.chat .message_list .new .start_chat {
  background-color: #0d894d;
  padding: 0.7em 0.7em;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  width: 80%;
  transition: 0.3s background-color ease;
}

.inner-page.chat .message_list .new .start_chat:hover {
  background-color: #0b7944;
}

.inner-page.chat .message_list .list_main .item {
  padding: 5px 2px;
  height: 64px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  cursor: pointer;
}

.inner-page.chat .message_list .list_main .item:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.inner-page.chat .message_list .list_main .item .img {
  flex-basis: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-page.chat .message_list .list_main .item .img div {
  width: 40px;
  height: 40px;
  position: relative;
}

.inner-page.chat .message_list .list_main .item .img img {
  width: 100%;
  height: auto;
}

.inner-page.chat .message_list .list_main .item .img span {
  display: none;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  /* background-color: #2DCA73; */
  background-color: #0099ff;
  position: absolute;
  bottom: -2px;
  left: 2px;
}

.inner-page.chat .message_list .list_main .item .info {
  flex-basis: 80%;
  width: 80%;
  display: flex;
  height: 100%;
  align-items: center;
}

.inner-page.chat .message_list .list_main .item ._g {
  width: 22%;
  text-align: right;
  padding-right: 8px;
}

.inner-page.chat .message_list .list_main .item ._f {
  width: 100%;
  overflow: hidden;
  text-align: left;
}

.inner-page.chat .message_list .list_main .item .info .name {
  width: 100%;
  text-overflow: ellipsis;
}

.inner-page.chat .message_list .list_main .item .info .date {
  color: rgb(0 0 0 / 40%);
  font-size: 0.8rem;
}

.inner-page.chat .message_list .list_main .item .info .dtl {
  width: calc(100% - 10px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.9rem;
  color: rgba(46, 52, 59, 0.589);
}

.inner-page.chat .message_list .list_main .item .info .count {
  display: inline-flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 3px;
  background-color: rgba(13, 137, 77, 0.863);
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
}

.inner-page.chat .message_list .list_main .item.open {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.inner-page.chat .message_list .list_main .item.online .img span {
  display: inline-block;
}

.inner-page.chat .message_list .list_main .item.unread {
  font-weight: 500;
}

.inner-page.chat .message_list .list_main .item.unread .count {
  visibility: visible;
}

.inner-page.chat .message_body {
  flex-basis: 70%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
}

.inner-page.chat .message_header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.inner-page.chat .message_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.inner-page.chat .message_header .user_detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.inner-page.chat .message_header .user_detail .dp {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: auto 10px;
}

.inner-page.chat .message_header .user_detail .dp img {
  width: 100%;
  height: auto;
}

.inner-page.chat .message_header .user_detail ._oth .name {
  font-weight: 500;
  line-height: 1;
}

.inner-page.chat .message_header .user_detail ._oth .status {
  color: rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  display: none;
}

.inner-page.chat
  .message_body.online
  .message_header
  .user_detail
  ._oth
  .status {
  display: block;
}

.inner-page.chat .message_compose {
  background-color: #fff;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.inner-page.chat .message_compose input {
  border: none;
  background-color: #f2f2f2;
  width: 100%;
  height: 35px;
  resize: none;
  border-radius: 50px;
  outline: none;
  padding: 2px 12px 2px;
}

.inner-page.chat .message_compose button {
  background: none;
  border: none;
  color: #808286;
}

.inner-page.chat .message_compose button:hover {
  color: #4d4d4d;
}

.inner-page.chat .message_main {
  background-color: #f0f0f0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 6px 6px;
}

.inner-page.chat .message_main ._msg {
  display: flex;
}

.inner-page.chat .message_main .time_stamp {
  display: block;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
  margin: 15px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.inner-page.chat .message_main ._msg.out {
  justify-content: flex-end;
  color: #fff;
}

.inner-page.chat .message_main ._msg.out ._inr {
  background-color: rgba(13, 137, 77, 0.863);
}

.inner-page.chat .message_main ._msg.in {
  justify-content: flex-start;
}

.inner-page.chat .message_main ._msg.in ._inr {
  background-color: #fff;
}

.inner-page.chat .message_main ._msg.in + ._msg.in {
  margin-top: -7px;
}

.inner-page.chat .message_main ._msg.out + ._msg.out {
  margin-top: -7px;
}

.inner-page.chat .message_main ._msg ._inr {
  max-width: 65%;
  padding: 6px 7px 2px 9px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 6.5px;
  margin: 5px 0;
}

.inner-page.chat .message_main ._msg ._inr ._tme {
  height: 18px;
  color: var(--bubble-meta);
  font-size: 0.75rem;
  line-height: 18px;
  white-space: nowrap;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.63);
}

.inner-page.chat .message_main ._msg.out ._inr ._tme {
  color: rgb(223, 223, 223);
}

.inner-page.chat .new_chat {
  flex-basis: 30%;
  /* box-shadow: 1px 0 2px rgba(77, 77, 77, 0.192); */
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  display: grid;
  grid-template-rows: 60px 60px 1fr;
}

.inner-page.chat .new_chat .nw_ {
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0 10px;
}

.inner-page.chat .new_chat .nw_ .bk:hover {
  cursor: pointer;
}

.inner-page.chat .new_chat .nw_ .tx_ {
  margin-left: 5px;
  font-size: 1.1rem;
}

.inner-page.chat .new_chat .search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.inner-page.chat .new_chat .search .search_bar {
  width: 90%;
}

.inner-page.chat .new_chat .search input {
  background-color: #f5f5f5;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  height: 2.5rem;
}

.inner-page.chat .new_chat .list_main {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 5px;
}

.inner-page.chat .new_chat .list_main .item {
  display: flex;
  padding: 5px 5px;
  cursor: pointer;
}

.inner-page.chat .new_chat .list_main .item:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.inner-page.chat .new_chat .list_main .item .img {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
}

.inner-page.chat .new_chat .list_main .item .img img {
  width: 100%;
  height: auto;
}

.inner-page.chat .new_chat .list_main .item .name {
  font-weight: 500;
  margin-left: 5px;
  margin-top: 3px;
}

/* Reset Select */
._k_custom_select_one select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #0d894d;
  background-image: none;
}
/* Remove IE arrow */
._k_custom_select_one select::-ms-expand {
  display: none;
}
/* Custom Select */
._k_custom_select_one {
  position: relative;
  display: flex;
  width: 7.3em;
  height: 2.4em;
  line-height: 2.4;
  background: #0da15a;
  overflow: hidden;
  border-radius: 0.25em;
}
._k_custom_select_one select {
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}
/* Arrow */
._k_custom_select_one::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.7em;
  color: white;
  background: #0da15a;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

/* Transition */
._k_custom_select_one:hover::after {
  color: rgb(212, 208, 208);
}

._k_custom_select_one option {
  background: rgba(219, 209, 209, 0.3);
  color: #fff;
}

/* Landing page styles */

* {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
}

@keyframes moveInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.header-background {
  background: url("../header-background.png");
  min-height: 100vh;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-body {
  margin-top: 120px;
}

.btn-green {
  background-color: #037b40;
  color: #fff;
  border: 1px solid #037b40;
  font-weight: 500;
}

.btn-green:hover {
  color: #fff;
  opacity: 0.9;
  transition: 0.3s;
}

.btn-white {
  background-color: #fff;
  color: #037b40;
  font-weight: 500;
  border: 1px solid #037b40;
}
.secondary-color {
  color: #037b40;
}
a {
  color: inherit;
}

.card-custom {
  background-color: white;
  height: 150px;
  width: 70px;
  border: 1px solid black;
}

a:hover {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.btn-white:hover {
  color: #037b40;
  opacity: 0.9;
  transition: 0.3s;
}

.green-circle {
  height: 45px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #18b768;
  border: 1px solid #18b768;
}

.secondary-green {
  color: #18b768;
}

.main-body {
  background-color: #f9fffc;
  overflow: hidden;
}

.accent-color {
  color: #ffba49;
}

.options-link {
  background-color: #fff;
  width: inherit;
  padding: 10px;
  height: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
}

.text-black {
  color: #0000;
}

.nav-link__dropdown {
  width: 5em;
}

.desktop-nav {
  gap: 20px;
}

.navbar-landing {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  border-bottom: 1px solid #eceeed;
  position: fixed;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar-landing .container,
.navbar-landing .container-fluid,
.navbar-landing .container-sm,
.navbar-landing .container-md,
.navbar-landing .container-lg,
.navbar-landing .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.green-eclipse_outline {
  background: url("../green-eclipse.png");
  background-repeat: no-repeat;
  height: 160px;
  transform: translate(-97px, 100px);
  margin-top: 80px;
  margin-bottom: 48px;
  width: 100%;
}

.right-text__animation {
  animation: moveInRight;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}
.left-text__animation {
  animation: moveInLeft;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.triangle-t_right-text {
  margin-left: 50px;
}
.dashboard-img {
  /* position: absolute; */
  top: 0rem;
  bottom: 5rem;
  z-index: 10;
  /* width: 94%; */
  height: 274px;
}

.ellipse-b__right-text {
  margin-left: 50px;
}

.triangle-b_right-text {
  position: absolute;
  left: 17rem;
}

.first-group {
  margin-top: 80px;
}

.second-group {
  margin-top: 80px;
  position: relative;
}

.features {
  margin-top: 150px;
}

.invoice-svg {
  transform: translate(-56px, -56px);
}

.start {
  min-height: 160px;
  max-height: 100%;
  align-items: center;
}

.footer-landing {
  padding-top: 60px;
  color: #fff;
  margin-top: 70px;
  background-color: #11311a;
  min-height: 25vh;
  max-height: 40vh;
  width: 100%;
}

.footer-list {
  list-style: none;
}

.copyright-landing {
  font-size: 20px;
}

.img-mg {
  position: relative;
}

.feature-group-one__animation {
  animation: moveInUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.feature-group-one-left__animation {
  animation: moveInUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.feature-group-two__animation {
  animation: moveInUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.feature-group-two-left__animation {
  animation: moveInUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.feature-group-three__animation {
  animation: moveInUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.start-animation {
  animation: moveInUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}

.nav-custom {
  height: 80px;
  background-color: #fff !important;
  z-index: 50;
}

.nav-wrapper {
  height: 20vh;
  background-color: none;
}

.third-section {
  background-color: #f1fff8;
}

.fourth-section {
  clip-path: polygon(0 0, 100% 0, 100% 94%, 64% 91%, 33% 91%, 0 94%);
  background-color: #037b40;
  min-height: 100vh;
  max-height: 100%;
}

.fourth-section__dots {
  position: absolute;
  top: 1.5em;
  left: 2.5em;
}

.fourth-section__dots-container {
  position: relative;
  margin-bottom: 50px;
}

.fourth-section__dots-right {
  position: absolute;
  top: -5.5em;
  right: 2.5em;
}

.card-custom__light-green {
  background-color: #edfff6;
  height: 400px;
  /* width: 60%; */
  padding: 50px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
}

.secondary-color_background {
  background-color: #046a37;
}

/* .download-app {
  align-items: center;
} */

.btn-green-white_border {
  background-color: #046a37;
  color: #fff;

  font-weight: 500;
  border: 1px solid #fff;
}

.btn-green-white_border:hover {
  color: #fff;
  opacity: 0.7;
  transition: 0.3s;
}

.getStarted {
  height: 10rem;
  background: #037b40;
  padding-left: 10rem;
  padding-right: 10rem;
}

.getStarted > div > p {
  font-size: 50px;
}

.gstartedbtn > button {
  height: 4.2rem;
  width: 13rem;
  border: 2px solid white;
}

.bottom-menu > div > p {
  font-weight: 700;
  color: #079651;
}

.bottom-wrapper {
  color: #079651;
}

.bottom-terms {
  text-decoration: underline;
}

.big-orange-ellipse__right {
  transform: translate(218px, -45%);
}

.list-features__container {
  list-style: none;
}

.second-to-last-section {
  margin-top: 70px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  border-radius: 2px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #000;
}

.card-custom-landing {
  min-height: 300px;
  max-height: 100%;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 15x; */
}

.btn-hamburger {
  background-color: transparent;
  border: none;
}

.arrow-down {
  fill: #047b40;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 70px;
    gap: 0;
    flex-direction: column;
    color: #037b40;
    width: 100%;
    align-items: end;
    text-align: center;
    transition: 0.6s ease-in-out;
    background-color: white;
    z-index: 30;
  }
  .nav-menu.active {
    left: 0;
  }

  .arrow-down {
    fill: #037b40;
  }
}

/* Addiontal styles */
.show-more {
  background: rgba(197, 194, 194, 0.13);
}

.show-more:hover {
  background: rgba(197, 194, 194, 0.23);
}

.term_and_condition {
  position: absolute;
  bottom: 50px;
  right: 0px;
  text-decoration: underline;
}

.nameCircle {
  width: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  background-image: linear-gradient(270deg, rgb(0, 132, 255), rgb(140, 0, 255));
}

.navCircle {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  font-family: "Roboto", sans-serif;

  background-image: linear-gradient(270deg, rgb(0, 132, 255), rgb(140, 0, 255));
}

.wrapper{
  display:inline-block;
  position:relative
}

.btnimg{
  pointer-events:none;

  opacity:1;
  line-height:90px;
  text-align:center;
}

.image_input{
  opacity:0;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.overlay_image{
  position: absolute;
  bottom:5px;
  right:10px;

}

#camera-icon {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)) !important;
}

.no_image{
  width: "130px"; line-height: "130px"; font-size: "45px"; padding: "20px";
  border-radius: 50%;
  text-align: center;
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  background-image: linear-gradient(270deg, rgb(0, 132, 255), rgb(140, 0, 255));
}