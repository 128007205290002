.loader {
    border: 10px solid #ece9e9;
    border-radius: 50%;
    border-top: 10px solid #0d894d;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    margin: auto;
}


/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader__container {
    /* border: 1px solid red; */
    height: 100%;
    /* width: 100%; */
    z-index: 1;
    position: static;
    display: flex;
    background-color: inherit;
    margin: auto;
}